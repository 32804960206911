.first{
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 5vw;
	box-sizing: border-box;
	min-height: 80vh;
	justify-content: space-around;
}
.botonAzul{
	display: flex;
	justify-content: center;
}
.botonAzul>a,
.botonAzul>div{
	text-decoration: none;
	color: white;
	border-radius: 1em;
	background-image: linear-gradient(to right, dodgerblue, darkslateblue);
	padding: 1em;
}
.inicio section{
	display: flex;
	padding: 0 2rem;
}
.inicio section>div{
	flex-grow: 1;
	flex-basis: 0;
	padding: 2em;
	box-sizing: border-box;
}
.inicio section>div:first-child{
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	text-align: left;
}
.inicio section>div:first-child h2{
	font-size: 2.5rem;
	text-align: left;
}
.inicio section:nth-of-type(even){
	flex-direction: row-reverse;
}
.divImg img{
	max-height: 30rem;
	filter: drop-shadow(0 0 10px lightgrey);
}
.divImg{
	display: flex;
	align-items: center;
	justify-content: center;
}
.titPrincipal{
	font-size: 4rem;
	text-align: center;
}
.inicio .titPrincipal{
	padding: 0 2rem;
}
.textoC{
	font-size: 1.5rem;
}

@media screen and (max-width:1080px){
	.divImg img{
		max-width: 25rem;
	}
	.inicio section>div:first-child h2 {
    font-size: 2rem;
}
.titPrincipal {
    font-size: 3.5rem;
}

}
@media screen and (max-width:770px){
	.titPrincipal{
    font-size: 2.5rem;
    padding: 0;
  }
  .inicio section {
    flex-direction: column-reverse!important;
    padding: 2rem;
}
.inicio section>div {
    padding: 0em;
}
.botonAzul{
	margin: 2rem 0;
}
}
@media screen and (max-width:430px){
	.titPrincipal{
    font-size: 1.5rem;
  }
  .textoC{
  	font-size: 1rem;
  	text-align: center;
  }
  .first{
  	min-height: 70vh;
  	padding: 4rem 1rem;
  }
  .inicio section{
  	flex-direction: column-reverse!important;
  	margin: 2rem 0;
  }
  .inicio section>div{
  	padding: 0;
  }
  .imgInicio{
  	width: 60vw;
  }
  .inicio section>div:first-child h2 {
    font-size: 1.5rem;
    text-align: center;
	}
	.botonAzul{
		margin: 1rem;
	}
	.inicio section>div:first-child {
    	text-align: justify;
	}
}