.preguntasF{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.preguntasF>div{
  width: 80%;
  margin: 1rem;
  border-radius: 1rem;
  box-shadow: 0 0 5px lightgrey;
  padding: 1rem;
  box-sizing: border-box;
}
.preguntasF>div>p:first-child{
  font-size: 2rem;
  font-weight: 200;
  margin: 1rem 0;
}
