.tarNueva{
	display: flex;
	align-items: center;
	width: 80%;
}
.tarNueva img{
	margin: 1rem!important;
}
.tarNueva>div:last-child{
	padding: 1rem;
	box-sizing: border-box;
}
.titNT{
	font-size: 2.5rem;
	font-weight: bolder;
}
.medAmbiente section{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.medAmbiente section>div:first-child{
	margin:4rem 0 2rem 0;
}

@media screen and (max-width: 1080px) {
	
}

@media screen and (max-width:770px){
	.tarNueva{
		flex-direction: column-reverse;
		width: 100%;
		margin-bottom: 2rem;
	}
	.titNT{
		font-size: 1.5rem;
	}
}
@media screen and (max-width:430px){

}