section.contactanos{
	display: flex;
	min-height: 90vh;
	flex-direction: column;
}
.formContact{
	min-height: 50vh;
}
.formContact textarea{ 
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;;

    resize:vertical;
}
.socials{
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	text-align: center;
}
.socials>div{
	display: flex;
	width: 100%;
	text-align: center;
}
.socials>div>div{
	flex-basis: 0;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.socials>div:first-child{
}
.medios{
	font-size: 2rem;
	font-weight: 300;
	height: 6rem;
	display: flex;
	align-items: center;
}
.imgSoc{
	height: 5em;
	width: 5em;
	display: flex;
	align-items: center;
	justify-content: center;
}
.imgSoc a{
	height: 5em;
	width: 5em;
	display: flex;
	align-items: center;
	justify-content: center;
}
.imgSoc img{
	max-height: 100%;
	max-width: 100%;
}

.minChat {
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 6px 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease-in-out;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
}

.minChat:focus {
  border-color: #1e90ff;
}
.titContacto h1{
	font-size: 4rem;
	text-align: center;
	margin-top: 3rem;
	margin-bottom: 1rem;
}
section.contactanos>div:last-child{
	display: flex;
}
section.contactanos>div:last-child>div{
	flex-grow: 2;
}
section.contactanos>div:last-child>form{
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}
section.contactanos>div:last-child>div,
section.contactanos>div:last-child>form{
	flex-basis: 0;
	margin: 2rem;
	padding: 1rem;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0 0 0.5rem lightgrey;
    border-radius: 1rem;
}
.titBajo{
	font-size: 1.5rem;
	text-align: center;
	margin: 1rem 0;
}
@media screen and (max-width: 1080px) {

}
@media screen and (max-width:770px){
	.contactanos>div:last-child{
		flex-direction: column;
	}
	.titContacto h1{
		font-size: 3rem;
	}

}
@media screen and (max-width:430px){
	.titContacto h1{
		font-size: 1.5rem;
	}
	.medios{
		font-size: 1rem;
		height: 5rem;
	}
	.imgSoc{
    height: 3em;
    width: 3em;
  }
	.imgSoc a {
    height: 3em;
    width: 3em;
  }
  .socials>div{
  	font-size: 0.8rem;
  }
}

