.blogsAdm{
	min-height: 80vh;
	display: flex;
	padding: 2rem;
	box-sizing: border-box;
	justify-content: space-evenly;
	flex-wrap: wrap;
}
.blogsAdm>div{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.blogsAdm .imgBlog{
	height: 10em;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}
.blogsAdm .imgBlog img{
	max-width: 100%;
	max-height: 100%;
}
.previaBlog img{
	max-width: 100%;
}
.tarBlog{
	width: 20rem;
	height: 30rem;
	box-sizing: border-box;    
	box-shadow:0 0 10px lightgrey;
	position: relative;
	background-color: lightcyan;
	border-radius: 1rem;
}
.tarBlog:before{
	content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg,hsla(0,0%,100%,0),rgba(0,0,0,.454) 30.38%,#00015a);
    border-radius: 1rem;
}
.titB{
	font-size: 1.5rem;
	font-weight: bold;
	color: white;
	z-index: 2;
	padding: 1rem;
	box-sizing: border-box;
}
.desB{
	margin: 0.5rem 0;
	color: white;
	z-index: 2;
	padding: 0 1rem;
	box-sizing: border-box;
}
.botonB{
	z-index: 2;
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	padding-top: 1rem;
}
.botonB>a{
	text-decoration: none;
    color: white;
    border-radius: 0.5rem;
    background-image: linear-gradient(to right, dodgerblue, darkslateblue);
    padding: 5px 10px;
    width: 4rem;
    display: block;
    text-align: center;
    border: none;
}
.botonB>a:hover{
    background-image: linear-gradient(to right, darkslateblue, dodgerblue)!important;
}