.contenedor{
  min-height: 100vh;
}
.cInicio{
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(img/svgPrueba.svg);
    overflow-x: hidden;
}
.engra{
  width: 1rem;
  margin-right: 0.5rem;
  animation-name: giro;
    animation-duration: 2s; 
    animation-iteration-count: infinite;
}
@keyframes giro{

  25%{
    transform: rotateZ(45deg);
  }
  50%{
    transform: rotateZ(90deg);
  }
  75%{
    transform: rotateZ(135deg);
  }
  100%{
    transform: rotateZ(180deg);
  }
}
nav{
  position: relative;
  display: flex;
  justify-content: space-around;
  padding: 2em 10vw 2em 20vw;
  box-sizing: border-box;
  min-height: 3em;
  align-items: center;
  z-index: 5;
}
nav .logo{
  display: block;
  width: 6em;
  height: 6em;
  position: absolute;
  left: 1rem;
  top: 0;
}
nav.fix{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  backdrop-filter: blur(6px);
}
.botonAzul{
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.botonAzul>a,
.botonAzul>div{
  text-decoration: none;
  color: white;
  border-radius: 1em;
  background-image: linear-gradient(to right, dodgerblue, darkslateblue);
  padding: 1em;
}
.flechaFalsa img{
  max-width: 100%;
  max-height: 100%;
  transition: all .2s;
}
.flechaFalsa{
  margin-left: 2px;
  height: 1em;
  width: 1em;
  display: inline-block;
}
nav .serviciosNav,
nav a{
  text-decoration: none;
  border-bottom: 2px solid transparent;
  color: black;
  padding: .5em;
}

.serviciosNav{
  position: relative;
}
.serviciosNav .despliega{
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 2px);
  border-radius: .5em;
  width: max-content;
  box-shadow: 0 0 4px lightgrey;
  padding: .5em 1em;
  box-sizing: border-box;
  transform: scale(0);
  opacity: 0;
  transform-origin: top left;
  transition: all .2s;
  background-color: white;
}
.serviciosNav .despliega a{
  padding: .25em 0;
}

footer a{
  color: white;
  text-decoration: none;
}
footer .active{
  color: lightblue;
  text-decoration: none;
}
nav .active{
  color: darkblue;
  border-color: darkblue;
}
footer{
  display: flex;
  background-color: rgb(38, 38, 38);
  color: white;
  padding-top: 2em;
  padding-bottom: 1em;
  font-weight: 300;
}
footer>div{
  flex-grow: 1;
  flex-basis: 0;
  padding: 0 4em;
}
footer>div:nth-child(2){
  text-align: center;
}
footer>div:nth-child(3){
  text-align: right;
  justify-content: space-around;
  align-items: flex-end;
}
footer>div{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
footer .subir{
  cursor: pointer;
  height: 3em;
  width: 3em;
  padding: .5em;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: .5em;
}
footer .subir img{
  max-width: 100%;
  max-height: 100%;
}
.logo img{
  max-height: 100%;
}
.footIzq>div{
  margin-bottom: 1rem;
}
.logoFooter{
  text-align: center;
}
.logoFooter img{
  width:10rem;
}
.subRa{
  color: lightgrey;
  text-decoration-line: line-through;
  display: flex;
  align-items: center;
}
.sinPad{
  text-decoration: none;
    color: white;
    border-radius: 0.5rem;
    background-image: linear-gradient(to right, dodgerblue, darkslateblue);
    padding: 5px;
    width: 4rem;
    display: block;
    text-align: center;
    border: none;
    cursor: pointer;
}
.sinPad:hover{
    background-image: linear-gradient(to right,darkslateblue , dodgerblue);
}
.delete-button {
  background: linear-gradient(to bottom, #ff7070, #ff4545);
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 10px;
  transition: background 0.3s;
  border-radius: 0.5rem;
  width: 4rem;
  display: block;
  text-align: center;
}

.delete-button:hover {
  background: linear-gradient(to bottom, #ff4545, #ff0000);
}

.delete-button:focus {
  outline: none;
}

.delete-button:active {
  background: linear-gradient(to bottom, #ff0000, #cc0000);
}
@media (hover: hover) {
  nav a:hover{
    color: darkblue;
  }
  .serviciosNav:hover .flechaFalsa img{
    transform: rotateZ(180deg);
    transform-origin: center;
  }
  .serviciosNav:active .despliega,
  .serviciosNav:focus .despliega,
  .serviciosNav:hover .despliega{
    transform: scale(1);
    opacity: 1;
  }
  .subRa:hover{
    color: lightgrey!important;
  }
}





@media screen and (min-width:770px){
  .hamburguesa{
    display: none;
  }
}

@media screen and (max-width:1080px){
  nav{
    padding-left: 20rem;
  }
}
@media screen and (max-width:770px){
  .despliega a{
    padding-left: 2rem!important;
  }
  nav .active {
    color: darkblue;
    border-color: transparent;
  }
  nav{
    padding: 0;
  }
  nav .logo{
    height: 3rem;
    padding: 0;
  }
  footer{
    flex-direction: column;
  }
  .hamburguesa{
    display: block;
    height: 2em;
    width: 2em;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
  .hamburguesa img{
    max-height: 100%;
    max-width: 100%;
  }
  nav>.serviciosNav,
  nav>a{
    display: none;
  }
  nav.muestra{
    position: fixed;
    top: 0;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 3rem;
    background-color: white;
  }
  nav.muestra>.serviciosNav,
  nav.muestra>a{
    display: initial;
  }
  nav.muestra .despliega{
    position: static;
    height: 0;
    padding: 0;
  }
  .serviciosNav{
    width: 100%;
  }
  .serviciosNav .despliega{
    width: 100%;
    transform: scale(0);
    opacity: 0;
    box-shadow: none;
  }
  .flechaFalsa{
    height: 100%;
    width: 2em;
    text-align: center;
  }
  .flechaFalsa img{
    max-height: 1.2em;
  }
  .serviciosNav.desplegar .despliega{
    margin-top: .5em;
    transform: scale(1)!important;
    opacity: 1;
    height: auto;
  }
  .serviciosNav .flechaFalsa img{
    transform: none;
    transform-origin: center;
  }
  .serviciosNav.desplegar .flechaFalsa img{
    transform: rotateZ(180deg);
  }
  nav .serviciosNav,
  nav>a:not(.logo){
    border-bottom: 1px solid lightgrey;
    width: 100%;
    padding: .5em 0;
  }
  .cInicio {
    background-position-y: -5rem;
}
  .logoFooter{
    padding: 1rem 0;
  }
  .logoFooter img{
    width: 7rem;
  }
  footer{
    padding: 0;
  }
  footer>div{
    padding: 1rem;
  }
  .footIzq{
    text-align: center;
  }
  footer>div:nth-child(3) {
    position: relative;
}
  footer .subir{
    position: absolute;
    left: 1rem;
    bottom: 1rem;
  }
  footer a{
    margin-bottom: 0.5rem;
  }
}


.conSwitch{
  display: flex;
  align-items: center;
}
.conSwitch input[type=checkbox]{
  display: none;
}
.conSwitch input[type=checkbox]+span{
  display: flex;
  width: 2.2em;
  height: 1.2em;
  border-radius: 1em;
  background-color: lightgrey;
  margin-left: .5em;
  align-items: center;
}
.conSwitch input[type=checkbox]+span .bola{
  height: 1em;
  width: 1em;
  background-color: white;
  border-radius: 1em;
  margin: .1em;
}
.conSwitch input[type=checkbox]:checked+span{
  background-color: dodgerblue;
  justify-content: end;
}
.navAdmin{
  margin-top: 2em;
  display: flex;
  justify-content: space-evenly;
}
.etiquet{
    display: flex;
    width: max-content;
    margin: 0.5rem;
    background-color: lavender;
    padding: 0.2rem 0.5rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    color: darkblue;
    text-decoration: none;
    
}