.sinbarra{
	display: none;
}
.previaBlog div,
.previaBlog span{
	background-color: transparent!important;
	text-align: justify;
}
.titPrev{
	font-size: 4rem;
	font-weight: bold;
}
@media screen and (max-width: 1080px) {
	.titPrev{
	font-size: 3rem;
	font-weight: bold;
}
}
@media screen and (max-width:770px){
		.titPrev{
	font-size: 2rem;
	font-weight: bold;
}

}
@media screen and (max-width:430px){

}