.ver{
	padding: 3rem;
	box-sizing: border-box;
}
.ver p{
	text-align: justify;
}
.myv{
	display: flex;
}
.myv>div{
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;	
}
.myv>div:first-child{
	margin-right: 1rem;
}
.myv>div:last-child{
	margin-left: 1rem;
}
.myv h2{
	font-size: 2rem;
}
.imgDon{
	max-height: 10rem;
}
.imgDon>img{
	max-width: 10rem;
	margin: 1rem;
	margin-left: 4rem;
}
.tarVal{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.tarVal>div{
	flex: 1;
	padding: 1rem;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	margin: 1rem;
    background-color: white;
    box-shadow: 0 0 0.5rem lightgrey;
    border-radius: 1rem;
    text-align: justify;
}
.secTit{
	text-align: center;
	font-size: 3rem;
}
.tarVal .value{
	text-align: center;
	font-weight: bolder;
	margin: 1rem 0;
}
@media screen and (max-width:1080px){

}
@media screen and (max-width:770px){
	.myv>div{
		flex-direction: column;
	}
	.ver{
		padding: 1rem!important;
	}
	.imgDon>img {
    max-width: 10rem;
    margin: 1rem;
}
.tarVal{
	flex-direction: column;
}
.secTit{
	font-size: 1.5rem;
}
}
@media screen and (max-width:430px){

}