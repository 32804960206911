.blogsAdm>div>a{
	text-decoration: none;
	color: black;
}
.categoriasBlog{
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	z-index: 2;
	position: relative;
}
.categoriasBlog>a,
.categoriasBlog>div{
}
.blogsUser{
	flex-direction: row!important;
	justify-content:  space-around;
	flex-wrap: wrap;
}
.blogsUser>div{
	font-size: 1.2em;
	box-sizing: border-box;
	justify-content: flex-start!important;
}
.blogsUser .tituloBlog{
	position: relative;
	cursor: pointer;
}
.blogsUser>div:hover .tituloBlog{
	text-decoration: underline;
}
.fechaBlog{
	font-weight: 200;
	font-style: italic;
	font-size: .9em;
	position: absolute;
	bottom: 1rem;
	right: 1rem;
	z-index: 2;
	color: white;
	text-align: right;
}
.blogsAdm.blogsUser .imgBlog{
	height: 15em;
}
.blogsUser .categoriasBlog{
	font-size: .7em;
}
.blogsUser .categoriasBlog>div{
}
.orden>span{
	margin:0 1rem;
	cursor: pointer;
}
.orden .sel{
	border-bottom: 1px solid black;
}
.flechaFalsa.rotar img{
	transform: rotateZ(180deg);
	transform-origin: center;
}
.blogsUser .tarBlog{
	cursor: pointer;
}
.tarBlog{
	width: 20rem;
	height: 30rem;
	box-sizing: border-box;    
	box-shadow:0 0 10px lightgrey;
	position: relative;
	background-color: lightcyan;
	border-radius: 1rem;
}
.tarBlog:before{
	content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg,hsla(0,0%,100%,0),rgba(0,0,0,.454) 30.38%,#00015a);
    border-radius: 1rem;
}
.fil{
	padding-top:3rem ;
	padding-left: 5rem;
	padding-right: 5rem;
	box-sizing: border-box;
}
.fil>div:first-child,
.filtrosBlog>div{
	margin-bottom: 0.5rem;
	cursor: pointer;
}
.filtroCat>label{
	margin: 0 1rem;
}
@media screen and (max-width: 1080px) {
	
}

@media screen and (max-width:770px){
	.fil{
	padding: 1rem!important;
	box-sizing: border-box;
}
.blogsUser{
	padding: 1rem!important;
}
.blogsUser>.tarBlog{
	margin-bottom: 2rem;
}
}
@media screen and (max-width:430px){

}