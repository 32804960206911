.blogsAdm>div>a{
	text-decoration: none;
	color: black;
}
.categoriasBlog{
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}
.categoriasBlog>a,
.categoriasBlog>div{
}