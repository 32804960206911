.imgServicios{
	height: 85vh;
	position: relative;
}
.servicios section{
	padding: 2rem 5rem;
	text-align: center;
}
.titSeccion{
	font-size: 4rem;
	font-weight: 200;
}
.divTarjetas{
	display: flex;
}
.divTarjetas>div{
	flex-basis: 0;
	flex-grow: 1;
	padding: 1rem;
	margin: 2rem;
	box-sizing: border-box;
	background-color: white;
	box-shadow: 0 0 0.5rem lightgrey;
	border-radius: 1rem;
}
.divTarjetas h4{
	font-weight: 500;
	font-size: 1.2rem;
}
.lista{
	padding-left: 1.5rem;
	box-sizing: border-box;
}
.lista>div{
	display: flex;
	margin-bottom: 1rem;
}
.textJ{
	margin: 1rem 0 2rem 0;
	text-align: justify;
}
.pointer {
  width: 15px;
  height: 20px;
  position: relative;
  background: rgb(63, 110, 221);
  margin-right: 1rem;
}
.pointer:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 10px solid white;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.pointer:before {
  content: "";
  position: absolute;
  right: -10px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 10px solid rgb(63, 110, 221);
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.imgServicios img{
	filter: drop-shadow(0px 1rem 1rem lightgrey);
}
.imgMundo{
	width: 10rem;
	position: absolute;
	left: calc(50% - 5rem);
	top: calc(75% - 5rem);
	animation-name: mover;
  	animation-duration: 1s; 
  	animation-fill-mode: forwards;
	opacity: 0;
}
.imgPoste{
	width: 20rem;
	position: absolute;
	left: calc(50% - 5rem);
	bottom: 0;
	animation-name: girar;
  	animation-delay: 1s; 
  	animation-duration: 2s;
  	animation-fill-mode: forwards;
  	opacity: 0;
}
.imgCajas1{
	width: 20rem;
	position: absolute;
	left: calc(35% - 10rem);
	bottom: 0;
	animation-name: rebote;
  	animation-delay: 3s; 
  	animation-duration: 1s;
  	animation-fill-mode: forwards;
  	opacity: 0;
}
.imgCajas2{
	width: 20rem;
	position: absolute;
	left: calc(65% - 10rem);
	bottom: 2rem;
	animation-name: tambalear;
  	animation-delay: 3s; 
  	animation-duration: 1s;
  	animation-fill-mode: forwards;
  	opacity: 0;
}
.imgAvion1{
	width: 15rem;
	position: absolute;
	left: 2rem;
	top: 0;
	animation-name: volarID;
  	animation-delay: 3s; 
  	animation-duration: 2s;
  	animation-fill-mode: forwards;
  	opacity: 0;
}
.imgAvion2{
	width: 10rem;
	position: absolute;
	left: 60%;
	top: 15%;
	animation-name: volarDI;
  	animation-delay: 3s; 
  	animation-duration: 2s;
  	animation-fill-mode: forwards;
  	opacity: 0;
}
@keyframes volarDI{
	0%{
	}
	100%{
		transform: translateX(5rem) translateY(-2rem) scale(0.5);
		opacity:1;
	}
}
@keyframes volarID{
	0%{
	}
	100%{
		transform: translateX(15rem) translateY(2rem) ;
		opacity:1;
	}
}
@keyframes tambalear{
	0%{
		transform: translateY(-15rem);
	}
	25%{

		transform: translateY(-3rem);
	}
	50%{
		transform: rotateZ(-5deg);
	}
	75%{
		transform: rotateZ(5deg);
	}
	100%{
		transform: rotateZ(0deg);
		opacity:1;
	}
}
@keyframes rebote{
	0%{
		transform: translateY(-5rem);
	}
	50%{
		transform: translateY(-2rem);
	}
	100%{
		transform: translateY(-3rem);
		opacity:1;
	}
}
@keyframes girar{
	0%{
	}
	100%{
		transform: rotateY(360deg);
		opacity:1;
	}
}
@keyframes mover{
	0%{
		transform: translateY(-1rem);
	}
	100%{
		transform: translateY(-8rem) scale(2);
		opacity:1;
	}
}
@media screen and (max-width: 1080px) {
	.imgServicios img{
		font-size: 0.5rem;
	}

		.imgMundo{
	width: 10rem;
	left: calc(50% - 5rem);
	top: calc(75% - 5rem);
}
.imgPoste{
	width: 15rem;
	left: calc(50% - 5.5rem);
	bottom: 5rem;
}
.imgCajas1{
	width: 15rem;
	left: calc(35% - 10rem);
	bottom: 2rem;
}
.imgCajas2{
	width: 15rem;
	left: calc(65% - 5rem);
	bottom: 6rem;
}
.imgAvion1{
	width: 12rem;
	left: -9rem;
	top: 4rem;
}
.imgAvion2{
	width: 6.5rem;
	left: 55%;
	top: 22%;
}
}
@media screen and (max-width:770px){
	.imgMundo{
	width: 6.5rem;
	left: calc(50% - 2.5rem);
	top: calc(75% - 2.5rem);
}
.imgPoste{
	width: 12rem;
	left: calc(50% - 2.5rem);
	bottom: 8rem;
}
.imgCajas1{
	width: 12rem;
	left: calc(35% - 6rem);
	bottom: 5rem;
}
.imgCajas2{
	width: 12rem;
	left: calc(65% - 2rem);
	bottom: 8rem;
}
.imgAvion1{
	width: 9rem;
	left: -9rem;
	top: 4rem;
}
.imgAvion2{
	width: 6.5rem;
	left: 55%;
	top: 22%;
}
	.imgServicios{
		height: 35rem;
	}
		.divTarjetas{
		flex-direction: column;
	}
	.divTarjetas>div{
		width: 100%;
		margin: 1rem 0;
		padding: ;
	}
		.titSeccion{
		font-size: 2rem;
		font-weight: bolder;
	}
}
@media screen and (max-width:430px){
	.imgMundo{
	width: 5rem;
	left: calc(50% - 2.5rem);
	top: calc(75% - 2.5rem);
}
.imgPoste{
	width: 10rem;
	left: calc(50% - 2.5rem);
	bottom: 8rem;
}
.imgCajas1{
	width: 10rem;
	left: calc(35% - 6rem);
	bottom: 5rem;
}
.imgCajas2{
	width: 10rem;
	left: calc(65% - 2rem);
	bottom: 8rem;
}
.imgAvion1{
	width: 7.5rem;
	left: -12rem;
	top: 4rem;
}
.imgAvion2{
	width: 5rem;
	left: 55%;
	top: 22%;
}
	.servicios section{
		padding: 1rem 2rem;
	}
	.titSeccion{
		font-size: 2rem;
		font-weight: bolder;
	}
	.divTarjetas{
		flex-direction: column;
	}
	.divTarjetas>div{
		width: 100%;
		margin: 1rem 0;
		padding: ;
	}
	.imgServicios{
		height: 35rem;
	}
}
