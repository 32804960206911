.uneteAux{
	padding: 2rem;
}
.uneteAux seccion{
	text-align: center;
}
.seccBene{
	margin-top: 3rem;
	text-align: center;
}
.contEti{
	display: flex;
	padding: 1rem;
	flex-wrap: wrap;
	justify-content: space-around;
}
.contEti>div{
	width: 25rem;
    padding: 1rem;
    margin: 2rem;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0 0 0.5rem lightgrey;
    border-radius: 1rem;
}
.etiBene>div:first-child{
	font-weight: bolder;
	font-size: 1.5rem;
	text-align: left;
	color: rgb(30, 30, 30, 1.0);
}
.etiBene>div:first-child::after{
	content: " ";
	display: block;
	width: 75%;
	border-bottom: 2px solid rgb(8, 83, 239, 1.0);
	margin: 0.5rem 0;
}
.etiBene>div:nth-child(2){
	font-size: 1rem;
	text-align: justify;
	color: gray;
}
.etiBene>div:last-child{
	max-width: 100%;
}
.imgBene{
	border-radius: 0.5rem;
	width: 80%;
	margin-top: 1rem;
}
@media screen and (max-width: 1080px) {
}
@media screen and (max-width:770px){
.etiBene{
	margin: 1rem 0!important;
}

}
@media screen and (max-width:430px){

}