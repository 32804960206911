.login{
	height: 30em;
	width: 30em;
	background: white;
	color: #2c2c2c;
	border-radius: 3em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0 0 5px lightgray;
}
.login>form{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.login>form img{
	max-width: 2em;
	max-height: 2em;
	margin-right: 1em;
}
.login>form>label{
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0em 1em;
	background-color: ghostwhite;
	margin: 5px;
	border-radius: 0.5em;
	border: 2px solid lightgrey;
}
.login input{
	background-color: ghostwhite;
	border: none;
	width: 100%;
}
.login input:focus{
	filter: none;
}
.login>img:first-child{
	position: relative;
	max-width: 60%;
}
.login>form>div>button{
	width: 10em;
}
.logError{
	text-align: center;
	color: darkred;
	padding: 2em;
}
.contLogin{
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
.h1A{
	font-size: 4rem;
	font-weight: bold;
}
.h1B{
	text-align: center;
}