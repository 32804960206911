.blogUser{
	padding: 0!important;
}
.efecto{
	background-color: rgb(14, 15, 40);
	transform: skewY(178deg);
	padding: 2rem;
	margin-bottom: 2rem;
}
.rotAjuste{
	transform: skewY(182deg)
}
.tBlog{
	font-size: 4rem;
	font-weight: bold;
	color: white;
	text-align: center;
}
.blogUser .previaBlog{
	padding: 0 15rem;
}
.volver{
	color: white;
}
@media screen and (max-width: 1080px) {
	.tBlog{
	font-size: 3rem;
	font-weight: bold;
}
}
@media screen and (max-width:770px){
	.tBlog{
	font-size: 2rem;
	font-weight: bold;
}

}
@media screen and (max-width:430px){

}