.editorBlog{
	min-height: 80vh;
	padding: 2em;
	box-sizing: border-box;
}
.editor{
	background-color: white;
	box-shadow: 0 0 5px lightgrey;
}
.public-DraftEditor-content{
	min-height: 50vh;
}
.public-DraftEditor-content:focus{
	background-color: #ecfaff;
}
.opcBlog{
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}
.prevImg{
	display: flex;
	max-height: 15em;
	align-items: center;
	justify-content: center;
}
.prevImg input{
	display: none;
}
.prevImg img{
	max-height: 15rem;
	max-width: 100%;
	box-shadow: 0 0 10px lightgrey;
	border-radius: 0.5rem;
}
.datGral{
	display: flex;
	margin: 1rem 0;
}
.datGral>div:first-child{
	flex-basis: 0;
	flex-grow: 1;
	padding-right:1rem ;
}
.datGral>div:last-child{
	flex-basis: 0;
	flex-grow: 1;
	padding-left:1rem ;
	max-height: 30rem;
	max-width: 40rem;
}
.categorias{
	display: flex;
	flex-wrap: wrap;
}
.categorias>span{
	display: flex;
	width: max-content;
	margin: 0.5rem ;
	background-color: lavender;
	padding: 0.2rem 0.5rem;
	box-sizing: border-box;
	border-radius: 0.5rem;
	color: darkblue;
}
.categorias>span>span:last-child{
	padding-left: 0.5rem;
	font-weight: bold;
	cursor: pointer;
}
@media screen and (max-width: 1080px) {
	
}

@media screen and (max-width:770px){
	.blogUser .previaBlog {
    padding: 1rem;
    box-sizing: border-box;
}
}
@media screen and (max-width:430px){

}


