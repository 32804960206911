.contVaca{
	background-color: #fff;
    border-radius: 1rem;
    box-shadow: 0 0 0.5rem #d3d3d3;
    box-sizing: border-box;
    flex-basis: 0;
    margin: 2rem;
    padding: 1rem 2rem;
}
.contVaca>div:not(.titNo){
	border-bottom: 1px solid lightgrey;
}
.contVaca>div:nth-child(2){
	border-top: 1px solid lightgrey;
}
.vacante{
	min-height: 5rem;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.vacante>div{
	flex-basis: 0;
	flex-grow: 1;
	display: flex;
	align-items: center;
}
.vacante>div:first-child{
	flex-grow: 2;
	font-weight: bold;
	font-size: 1.5rem;
	color: rgb(119, 155, 231, 1.0);
}
.dobleD{
	display: flex;
	flex-direction: column;
	align-items: flex-start!important;
}
.dobleD>div{
	display: flex;
	align-items: center;
	justify-content: center;
}
.vacante>div:last-child>div{
	margin: 0.2rem 0;
	display: flex;
	align-items: center;

}
.imgIco{
	width: 2rem;
	margin:0 0.5rem ;
}
.movil{
	display: none!important;
}
.WAO{
	display: none!important;
}
@media screen and (max-width: 1080px) {
}
@media screen and (max-width:770px){
	.movil{
		display: flex!important;
	}
	.vacante>div:nth-child(2){
		display: none!important;
	}
	.vacante{
		flex-direction: column;
		align-items: flex-start;
	}
		.contVaca{
		margin: 1rem 0;
	}
	.vacante>div:first-child {
		font-size: 1.2rem;
		margin: 0.5rem 0;
	}
	.imgIco{
		width: 1rem;
	}
	.auxNO{
		display: none!important;
	}
	.WAO{
		display: flex!important;
		width: 100%;
		align-items: center;
		justify-content: center;
	}
	.WAO img{
		width: 3rem;
	}

}
@media screen and (max-width:430px){

}